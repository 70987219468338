import { HeadingLevel, Typography, Link } from "@flaconi/nebula-react";
import React from "react";

export const H1 = (props: any) => (
  <Typography.Heading level={HeadingLevel.H1} {...props} />
);
export const H2 = (props: any) => (
  <Typography.Heading level={HeadingLevel.H2} {...props} />
);
export const H3 = (props: any) => (
  <Typography.Heading level={HeadingLevel.H3} {...props} />
);
export const Text = (props: any) => <Typography.Text {...props} />;
