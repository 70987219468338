import { Box, Typography } from "@flaconi/nebula-react";
import React from "react";
import LiveCode from "./live-code";
import { Highlight, themes } from "prism-react-renderer";
import ClipboardCopy from "./copy";
import Nebulatheme from "../nebula";
import * as styles from "./style.module.css";

function Code({ children, className, meta }: any) {
  const language = className ? className.replace(/language-/, "") : "";
  const code = children.trim();
  if (/live/.test(meta)) {
    return (
      <LiveCode
        code={code}
        noinline={/noInline/.test(meta)}
        language={language}
        white={/white/.test(meta)}
      />
    );
  }
  return (
    <div className={styles.editor}>
      <Highlight theme={Nebulatheme} code={code} language={language}>
        {({ style, tokens, getLineProps, getTokenProps }: any) => (
          <pre style={style}>
            {tokens.map((line: any, i: number) => (
              <div key={i} {...getLineProps({ line })}>
                {line.map((token: any, key: any) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
      <div className={styles.copy}>
        <ClipboardCopy value={code} />
      </div>
    </div>
  );
}
export default Code;
