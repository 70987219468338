import React from "react";
import * as styles from "./style.module.css";
import {
  FontFamilies,
  HeadingLevel,
  Typography,
  Space,
} from "@flaconi/nebula-react";
import useSiteMetadata from "../utils/use-site-metadata";
import { useLocation } from "@reach/router";
import { withPrefix, Link } from "gatsby";

function HeaderItem({ href, name }: any) {
  const location = useLocation();
  const link = withPrefix(href) + "/";
  const isCurrent = location.pathname.indexOf(link) > -1;

  return (
    <li
      className={`${styles.headerItem} ${isCurrent ? styles.selected : ""}`}
      key={name}
      style={{
        listStyleType: `none`,
        padding: `13px`,
      }}
    >
      <Link to={href} className={styles.link}>
        <Typography.Text>{name}</Typography.Text>
      </Link>
    </li>
  );
}
const Header: React.FC<any> = ({ title, menuLinks }: any) => {
  const siteMetadata = useSiteMetadata();

  return (
    <div
      style={{
        alignItems: "center",
      }}
      className={styles.headerbg}
    >
      <div className={styles.logo}>
        <Space orientation="horizontal" size="x2" alignment="center">
          <a href="/">
            <img
              src={siteMetadata.header.logoUrl}
              height="22"
              width="110"
            ></img>
          </a>
          <Typography.Heading level={HeadingLevel.H2}>
            {siteMetadata.title}
          </Typography.Heading>
        </Space>
      </div>
      <div className={styles.navItems}>
        <nav>
          <ul style={{ display: "flex", flex: 1 }}>
            {menuLinks.map((link: any) => (
              <HeaderItem href={link.link} name={link.name}></HeaderItem>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default Header;
