import React from "react";
import * as styles from "./style.module.css";
import {
  FontFamilies,
  HeadingLevel,
  Typography,
  Space,
  Sizes,
  LinkVariant,
} from "@flaconi/nebula-react";
import { useLocation } from "@reach/router";
import { withPrefix, Link } from "gatsby";

function NavItem({ href, children }: any) {
  const location = useLocation();
  const isCurrent = withPrefix(href) + "/" === location.pathname;

  return (
    <Link
      to={href}
      className={`${styles.navItem} ${isCurrent ? styles.selected : ""}`}
    >
      {children}
    </Link>
  );
}

const Sidebar: React.FC<any> = ({ menuItems }: any) => {
  return (
    <aside
      style={{
        alignItems: "center",
      }}
      className={styles.sidebar}
    >
      <Space orientation="vertical" size="x2">
        {menuItems.map((item: any) => (
          <React.Fragment key={item.title}>
            {item.children ? (
              <Space orientation="vertical" size="x">
                <Typography.Text size={Sizes.Small}></Typography.Text>
                {item.children.map((child: any) => (
                  <NavItem key={child.title} href={child.url}>
                    {child.title}
                    {child.children ? (
                      <div>
                        {child.children.map((subChild: any) => (
                          <NavItem key={subChild.title} href={subChild.url}>
                            {subChild.title}
                          </NavItem>
                        ))}
                      </div>
                    ) : null}
                  </NavItem>
                ))}
              </Space>
            ) : (
              <NavItem href={item.url}>{item.title}</NavItem>
            )}
            <hr className={styles.divider}></hr>
          </React.Fragment>
        ))}
      </Space>
    </aside>
  );
};
export default Sidebar;
