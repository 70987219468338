import {
  Button,
  ButtonType,
  CopySmall,
  CheckedSmall,
  ButtonSize,
} from "@flaconi/nebula-react";
import copy from "copy-to-clipboard";
import React from "react";

function ClipboardCopy({ value }: any) {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);
  const icon = { component: copied ? <CheckedSmall /> : <CopySmall /> };
  return (
    <Button
      aria-label="Copy to clipboard"
      onClick={() => {
        copy(value);
        setCopied(true);
      }}
      size={ButtonSize.small}
      type={ButtonType.tertiary}
      icon={icon}
    ></Button>
  );
}

export default ClipboardCopy;
