import React from "react";
import * as styles from "./style.module.css";
import {
  FontFamilies,
  HeadingLevel,
  Link,
  Typography,
  Space,
  LinkVariant,
} from "@flaconi/nebula-react";
import useSiteMetadata from "../utils/use-site-metadata";
import menuItems from "../nav.yml";
import Header from "./header";
import { useStaticQuery, StaticQuery, graphql } from "gatsby";
import Sidebar from "./sidebar";
import { useLocation } from "@reach/router";
import Head from "./head";

export const Layout: React.FC<any> = ({ children, pageContext }) => {
  const siteMetadata = useSiteMetadata();
  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <div className={styles.layout}>
      <Head />
      <Header
        className={styles.headerbg}
        menuLinks={siteMetadata.menuLinks}
      ></Header>
      <div className={styles.container}>
        {!isHome && <Sidebar menuItems={menuItems}></Sidebar>}
        <main className={styles.mainContent}>
          {pageContext.frontmatter.title && (
            <Typography.Heading level={HeadingLevel.H1}>
              {pageContext.frontmatter.title}
            </Typography.Heading>
          )}
          <Space orientation="horizontal" size="x2">
            {pageContext.frontmatter.storybook && (
              <Link
                href={pageContext.frontmatter.storybook}
                variant={LinkVariant.standalone}
              >
                Storybook
              </Link>
            )}
            {pageContext.frontmatter.figma && (
              <Link
                href={pageContext.frontmatter.figma}
                variant={LinkVariant.standalone}
              >
                Figma
              </Link>
            )}
            {pageContext.frontmatter.github && (
              <Link
                href={pageContext.frontmatter.github}
                variant={LinkVariant.standalone}
              >
                Source
              </Link>
            )}
          </Space>
          {children}
        </main>
      </div>
      <footer className={styles.footer}>
        <div>footer</div>
      </footer>
    </div>
  );
};
export default Layout;
