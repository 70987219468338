import { Box, Typography } from "@flaconi/nebula-react";
import React, { useState } from "react";
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live";
import scope from "../live-code-scope";
import ClipboardCopy from "./copy";
import * as styles from "./style.module.css";
import NebulaTheme from "../nebula";

function LiveCode({ code, noinline, white }: any) {
  const [liveCode, setLiveCode] = useState(code);
  const handleChange = (updatedLiveCode: string) => {
    setLiveCode(updatedLiveCode);
  };
  return (
    <LiveProvider scope={scope} code={liveCode} noInline={noinline}>
      <LivePreview
        className={`${styles.preview} ${white ? styles.white : ""}`}
      />
      <div className={styles.editor}>
        <LiveEditor
          theme={NebulaTheme}
          tabMode="focus"
          onChange={handleChange}
        />
        <div className={styles.copy}>
          <ClipboardCopy value={liveCode}></ClipboardCopy>
        </div>
      </div>
      <LiveError className={styles.error} />
    </LiveProvider>
  );
}

export default LiveCode;
