import Table from "rc-table";
import React, { useEffect, useState } from "react";
import * as Icons from "@flaconi/nebula-icon";
import Code from "../../../nebula-gatsby-theme/src/components/code";

export default function IconsList({ size }) {
  let iconNames = Object.keys(Icons);
  if (size === "small") {
    iconNames = iconNames.filter((name) => name.endsWith("Small"));
  }
  if (size === "large") {
    iconNames = iconNames.filter((name) => name.endsWith("Large"));
  }

  let result: any[] = [];
  for (const name of iconNames) {
    if (name !== "IconBase") {
      result.push(
        <Code meta="live" className="language-jsx">{`<${name} /> `}</Code>
      );
    }
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {result.map((icon, index) => {
        return (
          <div key={index} style={{ padding: "4px", width: "300px" }}>
            {icon}
          </div>
        );
      })}
    </div>
  );
}
