import Table from "rc-table";
import React from "react";
import * as styles from "./styles.module.css";
export default function ComponentProps({ data }) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Default",
      dataIndex: "default",
      key: "address",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return <Table columns={columns} data={data} className={styles.table} />;
}
