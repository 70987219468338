import { Layout } from "./src/components/layout";
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import "./global-styles.css";
import { MDXProvider } from "@mdx-js/react";
import Code from "./src/components/code";
import { H1, H2, H3, Text } from "./src/components/nebula-components";
import mdxComponents from "./src/mdx-components";
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return <Layout pageContext={props.pageContext}>{element}</Layout>;
};

const components = {
  pre: (props) => props.children,
  code: Code,
  h1: H1,
  h2: H2,
  h3: H3,
  p: Text,
  ...mdxComponents,
};
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
